import React from 'react'
import { graphql } from 'gatsby'
// import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Img from "gatsby-image";
import { theme } from "../styles";
import styled from '@emotion/styled'
import { Wrapper } from '../components'
import website from '../../config/website'
// import SEO from '../components/SEO'

import {
  CatalogItem,
  CatalogHeader,
} from '../components'
import {
  CatalogWrapper
} from '../templates/catalogItemStyle'

const IndexWrapper = Wrapper.withComponent('main')
const Inner = styled.div`
  .gatsby-image-wrapper {
    max-width: 100% !important;
  }
  img {
    max-width: 100% !important;
    display: block;
  }
  p {
    margin: 1rem auto;
    font-family: "Lato" !important;
  }
  h2 {
    font-family: "Lato" !important;
    font-size: 2.4rem;
    margin: 0 0 0.5rem 0;
    font-weight: 500;
    color: #6d6d6d;
    text-transform: uppercase;
  }
  h3 {
    font-family: "Lato" !important;
    font-size: 1.4rem;
    margin: 0 0 2rem 0;
    font-weight: 300;
    color: #6d6d6d;
    text-transform: uppercase;
    span {
      font-weight: 500;
    }
    &.h2 {
      font-size: 1.8rem!important;
    }
  }
`;

const Head = styled.div`
  position: relative;
  margin-bottom: 1.35rem;
`;

const Hero = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  > div {
    width: 50%;
    &:first-of-type {
      padding-right: 1rem;
    }
    &:last-of-type {
      padding-left: 1rem;
    }
    p {
      margin-bottom: 1.35rem;
      font-size: 1.1rem;
      color: #6d6d6d;
    }
  }
  @media (max-width: ${theme.breakpoints.s}) {
    display: block;
    > div {
      width: 100%;
      &:first-of-type {
        padding-right: 0;
      }
      &:last-of-type {
        padding-left: 0;
      }
      p {
        &:first-of-type {
          padding-top: 1.35rem;
        }
      }
    }
  }
`;

// const Banner = styled.div`
//   position:relative;
//   margin-bottom: 2rem;
// `;

// const Gallery = styled.div`
//   position: relative;
// `;

const NovembreMMXXII = ({ 
  pageContext: { locale }, 
  // location, 
  data
}, props) => {
  const context = { slug: "exhibition", parent: "" };
  const document = [data.artwork];
  
  return (
    <React.Fragment>
      <Helmet title={`Disch Rémy - La lettre`}>
        <link rel="stylesheet" type="text/css" href="https://unpkg.com/xzoom/dist/xzoom.css" media="all" />
      </Helmet>
      <CatalogHeader {...context} letter />
      <IndexWrapper id={website.skipNavId} style={{ paddingBottom: 0}}>
        <Inner>
          <Head>
            <Img
              fixed={data.banner.childImageSharp.fixed}
              placeholderStyle={{
                filter: "grayscale(100%) contrast(100%)",
                imageRendering: "pixelated",
                opacity: 0,
              }}
            />
            <Hero style={{ position: 'absolute', bottom: 0, left: '1rem' }}>
              <div style={{ width: '100%' }}>
                <h3 style={{ color: '#fff', opacity: .8 }}>
                  <strong>La seconde naissance</strong><br />
                  <small>Novembre 2022</small><br />
                </h3>
              </div>
            </Hero>
          </Head>
          <Hero>
            <div style={{ width: '100%', paddingTop: '2rem', paddingLeft: 0, paddingRight: 0 }}>
              <p>
                Lors de la dernière lettre je vous présentais <a className="regular-link" href="https://www.dischr.com/le-jour-du-silence/" target="_blank">"Le jour du silence"</a>, une oeuvre grand format traçant la ligne d'un tournant 
                important dans la peinture de Rémy Disch.
              </p>
              <p>
                Fort du succès de cette oeuvre il signe aujourd'hui <strong>"La seconde naissance"</strong>, une huile sur toile s'inscrivant 
                dans la continuité d'une peinture tendant peu à peu vers l'abstraction.
              </p>
              <p>
                Pensée sur mesure pour un cadre élaboré par l'artisanat subtil de la Galerie Mirabeau, 
                c'est un écho intime et vibrant de multiples introspections, une porte ouverte.
              </p>
              <p>
                Une oeuvre unique, dont je serais ravi de vous parler dans un futur proche !
                <br />Ce tableau hors du temps saura sans aucun doute vous convaincre de soutenir un artiste tout aussi unique.
              </p>
            </div>
          </Hero>
        </Inner>
      </IndexWrapper>

      <CatalogWrapper className="fullscreen description letter" style={{ marginTop: '4rem' }}>
        <CatalogItem 
          key={`page-artwork-${1}`} 
          currentArtwork={{ document: document}}
          count={1}
          elementId={`artwork-1`}
          id={1}
          zoomPosition={'left'}
          hideprice
          hd
          cadre
          responsiveHeight
          // noPadding
        />
        <p 
          style={{ 
            marginTop: '2rem',
            textAlign: 'center',
            fontFamily: 'Roboto, sans-serif',
            color: '#1c252b'
          }}
        >
          <strong>La seconde naissance</strong><br />
          <span style={{
            fontSize: '16px',
            fontWeight: '100',
            color: '#1c252b'
          }}>huile sur toile, 79x54cm</span>
        </p>
      </CatalogWrapper>
      
      <IndexWrapper id={website.skipNavId} style={{paddingTop: 0}}>
        <Inner>
          <Hero>
            <div style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column'
            }}>
              <a 
                href="https://www.dischr.com/catalog" 
                target="_blank"
                style={{
                  border: '1px solid #b4cfb0',
                  background: '#b4cfb0',
                  color: '#f2f2f2',
                  fontWeight: 'bold',
                  display: 'block',
                  padding: '.5rem 1rem',
                  borderRadius: '5px'
                }}
              >
                Consulter les prix du catalogue
              </a>
              <a 
                href="mailto:davidbraud@dischr.com"
                style={{
                  fontSize: '14px',
                  textDecoration: 'underline',
                  display: 'block',
                  marginTop: '.5rem'
                }}
              >
                contact et informations
              </a>
            </div>
          </Hero>
        </Inner>
      </IndexWrapper>

      <IndexWrapper id={website.skipNavId}>
        <Inner>
          <Hero>
            <div style={{ width: '100%', paddingLeft: 0, paddingRight: 0 }}>
              <h3 style={{ marginTop: '5rem' }}>Nouvelles oeuvres</h3>
              <p>
                De nouvelles oeuvres ont vu le jour et ont été répertoriées sur le site internet.
                <br />Vous pourrez notamment y rencontrer <a className="regular-link" href="https://www.dischr.com/works/2022/" target="_blank">"Les rêves oubliés"</a>. 
                <br />Une huile sur toile (73x60cm) toute aussi émouvante qu'inatendue.
              </p>

              <h3 style={{ marginTop: '5rem' }}>Les catalogues</h3>
              <p>
                Vous pouvez accéder au <strong>catalogue des oeuvres 2021-2022</strong> à cette adresse : <br />
                {` `}<a className="regular-link" href={`https://www.dischr.com/catalog`} target="_blank">https://www.dischr.com/catalog</a>{` `} <br /><br />
                et depuis peu au <strong>catalogue fonds d'atelier</strong> à cette adresse : <br />
                {` `}<a className="regular-link" href={`https://www.dischr.com/catalog/fonds-d-atelier`} target="_blank">https://www.dischr.com/catalog/fonds-d-atelier</a>{` `}
              </p>
              
              <h3 style={{ marginTop: '5rem' }}>Acquérir des oeuvres</h3>
              <p>
                Si vous souhaitez acquérir et/ou vous informer de la disponibilité des oeuvres, 
                je vous invite à me contacter directement en utilisant l'adresse email et le numéro de téléphone en pied de page. 

              </p>
              <p>
                Vous pouvez également retrouver des oeuvres sur le site de notre partenaire <a className="regular-link" href="https://www.artsper.com/es/artistes-contemporains/france/102408/remy-disch" target="_blank">Artsper</a>
              </p>
              <p>
                <br /><br />Je vous souhaite à toutes et tous une bonne fin d'année.
                <br />À très bientôt,
              </p>
              <p>
                David Braud<br />
                <span style={{ paddingTop: '3rem', display: 'block' }}>
                  ---
                  <br />(+33) 651 453 881
                  <br /><a className="regular-link" href="mailto: davidbraud@dischr.com">davidbraud@dischr.com</a>
                  <br /><a className="regular-link" href={`https://www.dischr.com`} target="_blank">dischr.com</a>
                </span>
              </p>
            </div>
          </Hero>
        </Inner>
      </IndexWrapper>
    </React.Fragment>
  );
};

export default NovembreMMXXII;

export const pageQuery = graphql`
  query NovembreMMXXII {
    banner: file(name: {eq: "bandeau_lettre3"}) {
      childImageSharp {
        fixed(width: 1000, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    artwork: prismicArtwork(uid: {eq: "la-seconde-naissance"}) {
      prismicId
      data {
        meta_description
        meta_title
        title {
          text
        }
        drouot_estimate
        catalog_fullscreen
        type
        available
        on_demand
        price
        item {
          alt
          localFile {
            childImageSharp {
              fixed(height: 800, quality: 100) {
                ...GatsbyImageSharpFixed_withWebp
              }
              fluid(maxWidth: 800, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        cadre {
          alt
          localFile {
            childImageSharp {
              fixed(height: 800, quality: 100) {
                ...GatsbyImageSharpFixed_withWebp
              }
              fluid(maxWidth: 800, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        image_hd {
          localFile {
            childImageSharp {
              original {
                height
                src
                width
              }
            }
          }
        }
        categories {
          ... on PrismicArtworkDataCategories {
            category {
              uid,
              document {
                data {
                  name
                }
              }
            }
          }
        }
        year {
          ... on PrismicArtworkDataYear {
            uid
          }
        }
      }
    }
  }
`
